// SCSS file for the Conact Us page

// Glow shadows

.contact-bg {
	background: radial-gradient(at right 46%, rgba(115, 222, 222, 0.5) 0%, transparent 22%),
		radial-gradient(at left 74%, rgba(115, 222, 222, 0.5) 0%, transparent 22%), rgb(1, 7, 18);
}

// Background images

.bottom-left-image {
	position: absolute;
	left: 0;
	bottom: 20%;
	z-index: -1;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	pointer-events: none;
}

.bottom-left-image img {
	display: block;
	max-width: 400px;
	max-height: auto;
	pointer-events: none;
}

.bottom-right-image {
	position: absolute;
	right: 0;
	bottom: 20%;
	z-index: -1;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	pointer-events: none;
}

.bottom-right-image img {
	display: block;
	max-width: 500px;
	max-height: auto;
	pointer-events: none;
}

// Particles.js

.particles-contact {
	z-index: 0;
	position: absolute;
	width: 100%;
	height: 100%;
}

// Hero section

.contact-hero-bg {
	background: radial-gradient(at center top, rgba(115, 222, 222, 1) 0%, transparent 50%);
}

.blur-contact-hero-bg {
	z-index: 1;
	backdrop-filter: blur(240px);
}

.contact-hero-section {
	position: relative;
	height: 100%;
	z-index: 3;
	text-align: center;
	min-height: 100vh;
	overflow: hidden;

	h1 {
		font-size: 6rem;
		font-weight: 600;
		font-style: normal;
		line-height: 102px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}

	h2 {
		font-size: 1.25rem;
		font-weight: 400;
		font-style: normal;
		line-height: 30px;
		text-decoration: none;
		text-transform: none;
	}

	.contact-hero-content {
		gap: 64px;
		z-index: 99;
	}

	.contact-header-section {
		gap: 24px;
		display: flex;
		flex-direction: column;
	}

	.contact-items {
		justify-content: space-between;
		row-gap: 64px;
	}

	.icon-div {
		display: flex;
		width: 56px;
		height: 56px;
		padding: 14px;
		justify-content: center;
		align-items: center;
		border-radius: 28px;
		background: rgba(115, 222, 222, 0.2);
	}
}

// Get in touch section

.get-in-touch-section {
	padding: 96px 32px;
	border-radius: 32px;
	border: 2px solid rgba(115, 222, 222, 0.2);
	margin-top: 96px;

	.get-in-touch-content {
		display: flex;
		flex-direction: column;
		gap: 96px;
		align-items: center;
	}

	.get-in-touch-header {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: center;
	}

	.input-container {
		display: flex;
		align-items: center;
		border-radius: 5px;
		overflow: hidden;
		background: $color-neutral-900;
		border-radius: 32px;
		border: 1px solid $color-neutral-800;
		padding: 10px 14px;

		button {
			background: none;
			outline: none;
			border: none;
		}
	}

	.input {
		flex: 1;
		background: transparent;
		border: none;
		outline: none;
		padding: unset;
	}

	.phone-input-container {
		display: flex;
		align-items: center;
		border-radius: 5px;
		overflow: hidden;
		background: $color-neutral-900;
		border-radius: 32px;
		border: 1px solid $color-neutral-800;
	}

	.country-code-dropdown {
		background: transparent;
		color: white;
		border: none;
		cursor: pointer;
		outline: none;
		padding: 14px 30px 14px 18px;
		background: transparent url("../images/chevron-down.svg") no-repeat right 10px center;
		outline: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
	}

	.phone-number-input {
		flex: 1;
		background: transparent;
		border: none;
		outline: none;
		padding: 10px 14px 10px 0;
	}

	.custom-checkbox input[type="checkbox"] {
		visibility: hidden;
		position: absolute;
	}

	.custom-checkbox .checkmark {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 6px;
		border: 1px solid $color-neutral-700;
		background: $color-neutral-900;
		position: relative;
		cursor: pointer;
	}

	.custom-checkbox input[type="checkbox"]:checked + .checkmark {
		background: #fff;
	}

	.custom-checkbox .checkmark:after {
		content: "";
		position: absolute;
		display: none;
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid #000;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}

	.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
		display: block;
	}

	form {
		gap: 24px;
	}

	a {
		text-decoration: none;
	}

	input {
		border-radius: 32px;
		border: 1px solid $color-neutral-800;
		padding: 10px 14px;
		background: none;
		font-size: 1rem;
		font-weight: 500;
		font-style: normal;
		line-height: 24px;
		text-decoration: none;
		text-transform: none;
		color: $color-neutral-200;
		background: $color-neutral-900;
	}

	input::placeholder {
		font-size: 1rem;
		font-weight: 500;
		font-style: normal;
		line-height: 24px;
		text-decoration: none;
		text-transform: none;
		color: $color-neutral-500;
	}

	textarea {
		padding: 10px 14px;
		border-radius: 8px;
		border: 1px solid $color-neutral-800;
		background: rgb(17, 25, 38);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		font-size: 1rem;
		font-weight: 500;
		font-style: normal;
		line-height: 24px;
		text-decoration: none;
		text-transform: none;
		color: $color-neutral-200;
		min-height: 8rem;
	}

	textarea::placeholder {
		font-size: 1rem;
		font-weight: 500;
		font-style: normal;
		line-height: 24px;
		text-decoration: none;
		text-transform: none;
		color: $color-neutral-500;
	}

	input[type="checkbox"] {
		border-radius: 6px;
		border: 1px solid $color-neutral-700;
		width: 20px;
		height: 20px;
		background: $color-neutral-900;
	}

	input[type="submit"] {
		border-radius: 32px;
		border: 1px solid $color-primary-600;
		background: $color-primary-600;
		padding: 16px 28px;
		color: #eef2f6;
		font-size: 1.125rem;
		font-weight: 500;
		font-style: normal;
		line-height: 28px;
		text-decoration: none;
		text-transform: none;
	}

	input[type="submit"]:hover {
		border: 1px solid $color-primary-700;
		background: $color-primary-700;
	}
}

// FAQ section

.faq-section-container {
	display: flex;
	flex-direction: row;
	gap: 64px;
	justify-content: center;
	align-items: flex-start;
	padding: 0 80px;
}

.faq-section-content {
	gap: 64px;
	flex-wrap: wrap;
}

.faq-section-header {
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: left !important;

	.title {
		font-size: 4.5rem;
		font-weight: 500;
		font-style: normal;
		line-height: 90px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}
}

.items-container {
	min-width: 20rem;
}

details > summary {
	list-style: none;
}
details > summary::-webkit-details-marker {
	display: none;
}

.faq-item {
	padding: 32px;
	border-radius: 16px;
	cursor: pointer;

	&.selected {
		background: $color-neutral-800;
	}
}

.faq-item:hover {
	background: $color-neutral-800;
}

// Footer

.footer-section-divider-container {
	margin-top: 192px !important;
}

.footer-section-divider-minus-margin {
	margin-top: -288px;
}

.footer-minus-margin {
	margin-top: -480px;
}

@media only screen and (max-width: 600px) {
	// Hero section

	.contact-hero-section {
		padding: 0 20px;

		h1 {
			font-size: 3rem;
			font-weight: 600;
			font-style: normal;
			line-height: 60px;
			letter-spacing: -0.02em;
			text-decoration: none;
			text-transform: none;
		}

		h2 {
			font-size: 1rem;
			font-weight: 500;
			font-style: normal;
			line-height: 24px;
			text-decoration: none;
			text-transform: none;
		}

		.contact-hero-content {
			gap: 100px;
		}

		.contact-header-section {
			gap: 40px;
		}

		.contact-items {
			padding-bottom: 64px;
		}
	}

	// Get in touch section

	.get-in-touch-section {
		padding: 16px;

		.get-in-touch-header {
			gap: 20px;
			align-items: start;
		}
	}

	// FAQ section

	.faq-section-container {
		padding: 0 16px;
	}

	.faq-section-content {
		flex-direction: column;
	}

	.faq-section-header {
		.title {
			font-size: 3rem;
			font-weight: 500;
			font-style: normal;
			line-height: 60px;
			letter-spacing: -0.02em;
			text-decoration: none;
			text-transform: none;
		}
	}
}

@media only screen and (max-height: 940px) {
	// Hero section

	.contact-hero-section {
		padding-top: 8rem;

		.contact-hero-content {
			gap: 24px;
		}
	}
}

@media only screen and (max-width: 800px) {
	// Hero section

	.contact-hero-section {
		padding-top: 8rem;
	}
}

@media only screen and (max-width: 930px) {
	// Hero section

	.contact-hero-section {
		h1 {
			font-size: 5rem;
		}

		h2 {
			font-size: 1rem;
		}
	}
}

@media only screen and (max-width: 420px) {
	// Hero section

	.contact-hero-section {
		h1 {
			word-break: break-all;
		}
	}
}
