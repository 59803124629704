// SCSS file for the About Us page

// Glow shadows

.about-bg {
	background: radial-gradient(at right 20%, rgba(130, 57, 255, 0.6) 0%, transparent 12%),
		radial-gradient(at left 30%, rgba(130, 57, 255, 0.6) 0%, transparent 12%),
		radial-gradient(at left 72%, rgba(130, 57, 255, 0.6) 0%, transparent 8%),
		radial-gradient(at right 80%, rgba(130, 57, 255, 0.6) 0%, transparent 8%), rgb(1, 7, 18);
}

// Navbar override

.custom-header {
	.estimate-project {
		background-color: $color-secondary-600 !important;
		border: 1px solid $color-secondary-600 !important;
		color: #f8f3ff !important;
	}

	.estimate-project:hover {
		background-color: $color-secondary-700 !important;
		border: 1px solid $color-secondary-700 !important;
	}
}

// Particles.js

.particles-about {
	z-index: 0;
	position: absolute;
	width: 100%;
	height: 120vh;
}

// Hero section

.about-hero-bg {
	background: radial-gradient(at center top, rgba(130, 57, 255, 0.6) 0%, transparent 40%);
}

.about-blur-hero-bg {
	z-index: 1;
	backdrop-filter: blur(240px);
}

.about-hero-section {
	position: relative;
	height: 100%;
	z-index: 3;
	text-align: center;
	min-height: 100vh;

	h1 {
		font-size: 6rem;
		font-weight: 600;
		font-style: normal;
		line-height: 102px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}

	h2 {
		font-size: 1.25rem;
		font-weight: 400;
		font-style: normal;
		line-height: 30px;
		text-decoration: none;
		text-transform: none;
	}

	.about-hero-content {
		gap: 96px;
		z-index: 99;
	}

	.about-hero-header-content {
		gap: 48px;
	}

	.card {
		display: flex;
		padding: 24px 44px;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		border-radius: 32px;
		border: 1px solid $color-neutral-700;
		background: transparent;
	}

	.card-title {
		font-size: 96px;
		font-style: normal;
		font-weight: 600;
		line-height: 102px;
		letter-spacing: -1.92px;
	}

	.card-desc {
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
	}
}

// Press section

.press-section-content {
	display: flex;
	flex-direction: column;
	gap: 96px;
}

.press-section {
	text-align: center;
	padding: 0px 32px;

	p {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
}

// Our story section

.our-story-section {
	.our-story-container {
		display: flex;
		flex-direction: column;
		gap: 96px;
	}

	.our-story-content {
		display: flex;
		flex-direction: column;
		gap: 64px;
	}

	.our-story-title {
		font-size: 3rem;
		font-weight: 500;
		font-style: normal;
		line-height: 60px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}

	.our-story-header {
		row-gap: 14px;
	}

	.our-story-line {
		height: 1px;
		background: rgba(130, 57, 255, 0.6);
	}

	.our-story-button {
		background: none;
		border: none;
		color: $color-secondary-300;
	}

	.our-story-body {
		font-size: 1.25rem;
		font-weight: 400;
		font-style: normal;
		line-height: 30px;
		text-decoration: none;
		text-transform: none;
	}

	.our-story-body.hidden {
		display: none;
	}

	.our-story-body-columns {
		column-count: 2;
		column-gap: 64px;
	}
}

// How we work section

.how-we-work-section {
	.card-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 64px;
	}

	.card {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 44px 64px;
		border-radius: 32px;
		background: transparent;
		border: 1px solid $color-neutral-700;

		.card-title {
			font-size: 3rem;
			font-weight: 500;
			font-style: normal;
			line-height: 60px;
			letter-spacing: -0.02em;
			text-decoration: none;
			text-transform: none;
		}

		.card-line {
			height: 1px;
			background: $color-secondary-500;
			margin-left: 12px;
		}
	}

	.card-container .card:nth-child(2n) {
		flex-direction: row-reverse;
	}
}

// Meet our team section

.meet-our-team-section {
	.button-container {
		display: flex;
		align-items: center;
		gap: 12px;
		flex-wrap: wrap;

		.contact-us-button {
			padding: 12px 20px;
			border-radius: 32px;
			background: $color-neutral-800;
			color: $color-secondary-25;
			border: none;
			width: 100%;
			text-decoration: none;
			text-align: center;
		}

		.open-positions-button {
			padding: 12px 20px;
			border-radius: 32px;
			background: $color-secondary-600;
			color: $color-secondary-25;
			border: none;
			width: 100%;
		}

		.about-us-button:hover {
			background: $color-neutral-700;
		}

		.open-positions-button:hover {
			background: $color-secondary-700;
		}
	}

	.meet-our-team-slider {
		.card {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 24px;
			text-align: left;
			background: transparent;

			.img-div {
				width: 100%;
				max-height: 296px;
				overflow: hidden;
				display: flex;
				justify-content: center;
				border-radius: 16px;

				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

// Testimonial section

.about-testimonial-section {
	.about-testimonial-card {
		display: flex;
		padding: 64px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 32px;
		border-radius: 16px;
		border: 1px solid $color-secondary-700;
		background: rgba(43, 19, 85, 0.3);
		text-align: left;
	}

	.about-testimonial-card-desc {
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: 38px;
		color: $color-secondary-25;
	}

	.about-testimonial-card-person {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	.about-testimonial-card-person-details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
	}

	.about-testimonial-card-person-details p {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		color: $color-base-white;
	}

	.about-testimonial-card-person-details p:last-child {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		color: $color-secondary-100;
	}
}

@media only screen and (max-width: 600px) {
	// Particles.js

	.particles-about {
		display: none;
	}

	// Hero section

	.about-hero-section {
		padding: 0 20px;
		text-align: left;

		h1 {
			font-size: 3rem;
			font-weight: 600;
			font-style: normal;
			line-height: 60px;
			letter-spacing: -0.02em;
			text-decoration: none;
			text-transform: none;
		}

		h2 {
			font-size: 1rem;
			font-weight: 500;
			font-style: normal;
			line-height: 24px;
			text-decoration: none;
			text-transform: none;
		}

		.card {
			padding: 15px 27px;
			gap: 10px;
			border-radius: 20px;
			background: $color-neutral-900;
		}

		.card-title {
			font-size: 58.16px;
			font-style: normal;
			font-weight: 600;
			line-height: 61.795px;
			letter-spacing: -1.163px;
		}

		.card-desc {
			font-size: 14.54px;
			font-style: normal;
			font-weight: 400;
			line-height: 19.387px;
		}
	}

	// Our story section

	.our-story-section {
		.our-story-container {
			gap: 64px;
		}

		.our-story-title {
			font-size: 2.25rem;
			font-weight: 500;
			font-style: normal;
			line-height: 44px;
			letter-spacing: -0.02em;
			text-decoration: none;
			text-transform: none;
		}

		.our-story-body-columns {
			column-count: 1;
		}
	}

	// How we work section

	.how-we-work-section {
		.card {
			padding: 16px;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 20px;

			.card-title {
				font-size: 2.25rem;
				font-weight: 500;
				font-style: normal;
				line-height: 44px;
				letter-spacing: -0.02em;
				text-decoration: none;
				text-transform: none;
			}
		}

		.card-container .card:nth-child(2n) {
			flex-direction: column;
		}
	}

	// Meet our team section

	.meet-our-team-section {
		.button-container {
			flex-direction: column;
			width: 100%;
		}
	}

	// Testimonial section

	.about-testimonial-section {
		.about-testimonial-card {
			padding: 20px;
		}

		.about-testimonial-card-desc {
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}

		.about-testimonial-card-person {
			display: flex;
			align-items: center;
			gap: 16px;
		}

		.about-testimonial-card-person-details {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
		}

		.about-testimonial-card-person-details p {
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
		}

		.about-testimonial-card-person-details p:last-child {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
		}
	}
}

@media only screen and (max-height: 940px) {
	// Hero section

	.about-hero-section {
		padding-top: 8rem;
		padding-bottom: 192px;
	}
}

@media only screen and (max-width: 930px) {
	// Hero section

	.about-hero-section {
		h1 {
			font-size: 5rem;
		}

		h2 {
			font-size: 1rem;
		}
	}
}

@media only screen and (max-width: 420px) {
	// Hero section

	.about-hero-section {
		h1 {
			word-break: break-all;
		}
	}
}
