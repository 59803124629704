// SCSS file for the Landing (index.html) page

// Glow shadows

.index-bg {
	background: radial-gradient(at right 20%, rgba(51, 169, 255, 0.5) 0%, transparent 12%),
		radial-gradient(at left 20%, rgba(130, 57, 255, 0.5) 0%, transparent 12%),
		radial-gradient(at left 70%, rgba(115, 222, 222, 0.5) 0%, transparent 8%),
		radial-gradient(at right 75%, rgba(130, 57, 255, 0.5) 0%, transparent 8%),
		radial-gradient(at right 27%, rgba(247, 165, 149, 0.7) 0%, transparent 12%),
		radial-gradient(at left 38%, rgba(130, 57, 255, 0.5) 0%, transparent 12%), rgb(1, 7, 18);
}

// Subheading

.subheading-div {
	padding: 0 16px;
	border-radius: 1000px;
	background-color: rgba(255, 255, 255, 0.12);
	border: 1px solid rgba(225, 225, 226, 0.1);
}

// Particles.js

.particles-index {
	z-index: 0;
	position: absolute;
	width: 100%;
	height: 120vh;
}

// Bg elements div

.bg-elements-div {
	z-index: 0;
	position: absolute;
	width: 100%;
	height: 100vh;
}

// Our vision images

.our-vision-left-image {
	position: absolute;
	left: 0;
	top: 30%;
	z-index: -1;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	pointer-events: none;
}

.our-vision-left-image img {
	display: block;
	max-width: 260px;
	max-height: auto;
	pointer-events: none;
}

.our-vision-right-image {
	position: absolute;
	right: 0;
	top: 31%;
	z-index: -1;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	pointer-events: none;
}

.our-vision-right-image img {
	display: block;
	max-width: 260px;
	max-height: auto;
	pointer-events: none;
}

// Hero section

.index-hero-bg {
	background: radial-gradient(at center top, rgba(115, 222, 222, 0.6) 0%, transparent 40%);
}

.blur-hero-bg {
	z-index: 1;
	backdrop-filter: blur(240px);
}

.hero-section {
	position: relative;
	height: 100%;
	z-index: 3;
	text-align: center;
	min-height: 100vh;

	h1 {
		font-size: 6rem;
		font-weight: 600;
		font-style: normal;
		line-height: 102px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}

	h2 {
		font-size: 1.25rem;
		font-weight: 400;
		font-style: normal;
		line-height: 30px;
		text-decoration: none;
		text-transform: none;
	}

	.hero-content {
		gap: 48px;
		z-index: 99;

		.hero-subtitle {
			max-width: 820px;
		}
	}

	.estimate-project {
		padding: 16px 28px;
		background-color: $color-primary-600;
		border-radius: 32px;
		border: none;
		color: #eef2f6;
	}

	.estimate-project:hover {
		background-color: $color-neutral-700;
	}

	// Background images

	.center-left-image {
		position: absolute;
		left: 0%;
		bottom: -6%;
		z-index: -1;
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		pointer-events: none;
	}

	.center-left-image img {
		display: block;
		max-width: 400px;
		max-height: auto;
		pointer-events: none;
	}

	.center-right-image {
		position: absolute;
		right: 0%;
		bottom: -6%;
		z-index: -1;
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		pointer-events: none;
	}

	.center-right-image img {
		display: block;
		max-width: 400px;
		max-height: auto;
		pointer-events: none;
		transform: scaleX(-1);
	}

	.top-center-image {
		position: absolute;
		left: 50%;
		top: -2%;
		transform: translateX(-50%);
		z-index: -1;
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		pointer-events: none;
	}

	.top-center-image img {
		display: block;
		max-width: 1800px;
		max-height: auto;
		pointer-events: none;
	}
}

// Message section

.message-section {
	margin-top: 96px;
	a {
		text-decoration: none;
		color: $color-primary-500;
	}

	a:hover {
		color: $color-primary-700;
	}

	.slider-div {
		margin-bottom: -52px;
	}

	.card {
		display: flex;
		padding: 24px 44px;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		border-radius: 32px;
		border: 1px solid $color-neutral-700;
		background: transparent;
		transition: 0.5s ease-out;
	}

	.splide__track {
		padding: 3.6rem 0;
	}

	.card:hover {
		border: 1px solid $color-secondary-200;
		box-shadow: 0px 0px 60px 0px rgba(151, 71, 255, 0.4);
		transition: 0.5s ease-in;
	}

	.card-title {
		font-size: 96px;
		font-style: normal;
		font-weight: 600;
		line-height: 102px;
		letter-spacing: -1.92px;
	}

	.card-desc {
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
	}
	.message-section-subtitle {
		max-width: 960px;
	}
}

// Same dream section

.same-dream-section {
	.same-dream-section-subtitle {
		max-width: 960px;
	}
}

// Projects section

.projects-section {
	a {
		text-decoration: none;
	}
	p {
		margin: 0;
	}
	.card {
		display: flex;
		padding: 40px;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
		border-radius: 32px;
		border: 1px solid $color-neutral-700;
		background: rgba(0, 0, 0, 0.3);
		transition: 0.5s ease-out;
		cursor: pointer;
	}

	.card:hover {
		border: 1px solid $color-secondary-200;
		box-shadow: 0px 0px 60px 0px rgba(151, 71, 255, 0.4);
		transition: 0.5s ease-in;
	}
	.card-title-container {
		max-width: 100%;

		.card-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			@media only screen and (max-width: 500px) {
				font-size: 2rem;
			}
		}
	}
	.card-chip {
		padding: 6px 12px;
		background-color: $color-neutral-800;
		border: 1px solid $color-neutral-300;
		border-radius: 24px;
	}

	.card-desc {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;

		&.card-desc-revuto {
			@media only screen and (max-width: 1024px) {
				-webkit-line-clamp: 1;
				line-clamp: 1;
			}
		}
	}
}
// Proudly partnered section

.proudly-partnered-section {
	.proudly-partnered-card {
		border-radius: 52px;
		border: 3.2px solid rgba(231, 249, 249, 0.1);
		background: rgba(232, 232, 234, 0.05);
		backdrop-filter: blur(8px);
		width: 84%;
		height: fit-content;
		padding: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		gap: 64px 80px;
		flex-wrap: wrap;

		a {
			transition: transform 0.3s ease, box-shadow 0.3s ease;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

// Our vision section

.our-vision-section {
	.cards-div {
		gap: 44px;
	}

	.our-vision-card-wrap {
		perspective: 500px;
		cursor: pointer;
	}

	.our-vision-card {
		max-width: 520px;
		padding: 32px 40px;
		border-radius: 8px;
		border: 1px solid rgba(130, 57, 255, 0.4);
		background: rgba(130, 57, 255, 0.1);
		transition: transform 0.2s ease-out;
	}

	.linear-gradient-line-right {
		height: 1px;
		background: linear-gradient(270deg, #080b16 3.7%, #965aff 96%);
	}

	.linear-gradient-line-left {
		height: 1px;
		background: linear-gradient(270deg, #965aff 3.7%, #080b16 96%);
	}
}

// Conference section

.conference-section {
	.splide__slide {
		.conference-card {
			transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
			transform: scale(0.94);
		}
	}

	.splide__slide.is-active {
		.conference-card {
			transform: scale(1);
			border: 2px solid #364152;
		}
	}

	.card-blockchance-2021 {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url("../images/blockchance-2021.jpeg");
	}

	.card-blockchance-2023 {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url("../images/blockchance-2023.png");
	}

	.card-tmrw-2023 {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url("../images/tmrw-2023.jpeg");
	}

	.card-atd-2021 {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url("../images/atd-2021.jpeg");
	}

	.card-aibc-2022 {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url("../images/aibc-2022.jpeg");
	}

	.card-aibc-2022-2 {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url("../images/aibc-summit-2022.png");
	}

	a {
		text-decoration: none;
	}

	.conference-card {
		height: 640px;
		padding: 64px;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		display: flex;
		border-radius: 16px;
		text-align: left;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
		cursor: pointer;

		.card-location {
			display: flex;
			flex-direction: row;
			gap: 8px;
			align-items: center;
			justify-content: center;
			padding: 14px 32px 14px 32px;
			border-radius: 60px;
			background-color: rgba(26, 26, 26, 0.7);
		}

		.card-badge {
			border-radius: 60px;
			background: rgba(115, 222, 222, 0.1);
			padding: 14px 32px;
			width: min-content;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				height: auto;
				max-width: 350px;
			}
		}

		.card-title {
			font-size: 3.75rem;
			font-weight: 400;
			font-style: normal;
			line-height: 72px;
			letter-spacing: -0.02em;
			text-decoration: none;
			text-transform: none;
			width: 100%;
		}

		.card-desc {
			font-size: 30px;
			font-style: normal;
			font-weight: 400;
			line-height: 38px;
		}
	}
}

// Our Services section

.our-services-section {
	.pill-container {
		display: flex;
		gap: 20px;
		justify-content: center;
		flex-wrap: wrap;
	}

	.services-list-div {
		row-gap: 32px;
		column-gap: 64px;
	}

	.consulting-and-strategy-color-div {
		max-width: 100px;
		background-color: #73dede;
	}

	.solution-engineering-color-div {
		background-color: #8239ff;
		max-width: 100px;
	}

	.solution-engineering-item-bg-color {
		background-color: rgba(130, 57, 255, 0.2) !important;
	}

	.tailored-app-dev-color-div {
		background-color: #33a9ff;
		max-width: 100px;
	}

	.tailored-app-dev-item-bg-color {
		background-color: rgba(51, 169, 255, 0.2) !important;
	}

	.blockchain-and-dapps-color-div {
		background-color: #f47961;
		max-width: 100px;
	}

	.blockchain-and-dapps-item-bg-color {
		background-color: rgba(251, 210, 202, 0.2) !important;
	}

	.pill {
		display: flex;
		padding: 10px 20px;
		justify-content: center;
		align-items: center;
		border-radius: 32px;
		border: none;
		background: none;
	}

	.pill:hover {
		background: $color-neutral-800;
		color: $color-neutral-300;
	}

	.our-services-card {
		display: flex;
		padding: 44px 64px;
		flex-direction: column;
		align-items: center;
		gap: 64px;
		border-radius: 32px;
		background: transparent;
		border: 1px solid $color-neutral-700;

		.our-services-card-title {
			font-size: 48px;
			font-style: normal;
			font-weight: 500;
			line-height: 60px;
			letter-spacing: -0.96px;
		}

		.our-services-card-line {
			height: 1px;
		}

		.our-services-card-desc {
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 30px;
			text-align: left;
		}

		.our-services-card-feature-icon {
			display: flex;
			width: 56px;
			height: 56px;
			padding: 14px;
			justify-content: center;
			align-items: center;
			border-radius: 16px;
			background-color: rgba(115, 222, 222, 0.2);
		}

		.our-services-card-feature-text {
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			text-align: left;
			line-height: 32px;
			color: $color-neutral-300;
		}
	}
}

// Press section

.press-section-content {
	display: flex;
	flex-direction: column;
	gap: 96px;
}

.press-section {
}

// MTOS section

.mtos-section-content {
	display: flex;
	flex-direction: column;
	gap: 96px;

	.mtos-section {
		.mtos-header {
			font-family: Yellix;
			font-size: 124px;
			font-style: normal;
			font-weight: 600;
			line-height: 136.5px;
			letter-spacing: -2.48px;
		}

		.mtos-card {
			background: rgba(32, 41, 57, 0.2);
			backdrop-filter: blur(50px);
			padding: 6rem 5rem;
			border-radius: 2rem;
			position: relative;
			overflow: hidden;
			box-shadow: 0px 0px 150px 0px rgba(51, 169, 255, 0.25);

			&::before {
				content: "";
				position: absolute;
				inset: 0;
				border-radius: inherit;
				padding: 2px;
				background: linear-gradient(263.5deg, #ff5555 0%, #4886ff 34.5%, #7b61ff 68.5%, #73dede 100%);
				-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
				z-index: -1;
			}

			> * {
				position: relative;
				z-index: 1;
			}

			.contact-us-button {
				border-radius: 2rem;
				padding: 0.75rem 1.25rem;
				box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
				background: #171e28;
				border: none;
				color: #eef2f6;
				text-align: center;
			}

			.contact-us-button:hover {
				background: #253040;
			}

			.book-a-demo-button {
				border-radius: 2rem;
				padding: 0.75rem 1.25rem;
				box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
				background: #7f56d9;
				border: none;
				color: #eef2f6;
				text-align: center;
			}

			.book-a-demo-button:hover {
				background: rgb(98, 66, 168);
			}
		}

		.mtos-card::after {
			content: "";
			position: absolute;
			right: -30%;
			bottom: -15%;
			width: 75%;
			z-index: -1000;
			height: 100%;
			background-image: url("../images/mtos-screenshot.png");
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
}

// Testimonial section

.testimonial-section {
	.testimonial-card {
		display: flex;
		padding: 64px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 32px;
		border-radius: 16px;
		border: 1px solid rgba(115, 222, 222, 0.2);
		background: $color-neutral-900;
		text-align: left;
	}

	.testimonial-card-desc {
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: 38px;
	}

	.testimonial-card-person {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	.testimonial-card-person-details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
	}

	.testimonial-card-person-details p {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		color: $color-base-white;
	}

	.testimonial-card-person-details p:last-child {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		color: $color-primary-100;
	}
}

@media only screen and (max-width: 600px) {
	// Glow shadows

	.index-bg {
		background: rgb(1, 7, 18);
	}

	// Hero section

	.index-hero-bg {
		background: none;
	}

	.hero-section {
		padding: 0 20px;
		text-align: left;

		h1 {
			font-size: 3rem;
			font-weight: 600;
			font-style: normal;
			line-height: 60px;
			letter-spacing: -0.02em;
			text-decoration: none;
			text-transform: none;
		}

		h2 {
			font-size: 1rem;
			font-weight: 500;
			font-style: normal;
			line-height: 24px;
			text-decoration: none;
			text-transform: none;
		}
	}

	// Message section

	.message-section {
		.card {
			padding: 15px 27px;
			gap: 10px;
			border-radius: 20px;
		}

		.card-title {
			font-size: 58.16px;
			font-style: normal;
			font-weight: 600;
			line-height: 61.795px;
			letter-spacing: -1.163px;
		}

		.card-desc {
			font-size: 14.54px;
			font-style: normal;
			font-weight: 400;
			line-height: 19.387px;
		}
	}

	// Same dream section

	.same-dream-section {
	}

	// Proudly partnered section

	.proudly-partnered-section {
		.proudly-partnered-card {
			flex-direction: column;
			gap: 80px;
			width: 100%;
			padding: 64px 32px;
		}
	}

	// Our vision section

	.our-vision-section {
		display: none !important;
	}

	// Conference section

	.conference-section {
		.conference-card {
			padding: 32px;

			.card-location {
				padding: 10px 16px;
				border-radius: 60px;
			}

			.card-badge {
				border-radius: 26px;
				background: rgba(115, 222, 222, 0.1);
				padding: 14px;

				img {
					height: auto;
					max-width: 140px;
				}
			}

			.card-title {
				font-size: 1.875rem;
				line-height: 38px;
			}

			.card-desc {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}

	// Our services section

	.our-services-section {
		.pill-container {
			justify-content: flex-start;
		}

		.our-services-card {
			gap: 64px;
			padding: 44px 32px;

			.our-services-card-title {
				font-size: 36px;
				font-style: normal;
				font-weight: 500;
				line-height: 44px;
				letter-spacing: -0.72px;
			}

			.our-services-card-desc {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
			}

			.our-services-card-feature-icon {
				width: 40px;
				height: 40px;
				border-radius: 20px;
				padding: 10px;
			}

			.our-services-card-feature-text {
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px;
			}
		}
	}

	// MTOS section

	.mtos-section-content {
		.mtos-section {
			.mtos-logo {
				width: 7.75rem;
			}
			.mtos-card {
				padding: 3rem 2rem;
			}
		}
	}

	// Testimonial section

	.testimonial-section {
		.testimonial-card {
			padding: 20px;
		}

		.testimonial-card-desc {
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}

		.testimonial-card-person {
			display: flex;
			align-items: center;
			gap: 16px;
		}

		.testimonial-card-person-details {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
		}

		.testimonial-card-person-details p {
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
		}

		.testimonial-card-person-details p:last-child {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
		}
	}
}

@media only screen and (max-height: 940px) {
	// Hero section

	.hero-section {
		padding-top: 8rem;
		padding-bottom: 192px;

		.hero-content {
			gap: 24px;
		}
	}
}

@media only screen and (max-width: 930px) {
	// Hero section

	.hero-section {
		h1 {
			font-size: 5rem;
		}

		h2 {
			font-size: 1rem;
		}
	}
}

@media only screen and (max-width: 1200px) {
	// Our services section

	.our-services-section {
		.our-services-card-line {
			display: none;
		}
	}
	// MTOS section

	.mtos-section-content {
		.mtos-section {
			.mtos-card::after {
				background-image: none;
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	// Hero section

	.hero-section {
		h1 {
			font-size: 3rem;
			line-height: 60px;
			letter-spacing: -0.02em;
		}
		h2 {
			text-align: center;
		}
		.hero-title {
			padding-left: 0;
			padding-right: 0;
		}

		.hero-subtitle {
			p {
				font-size: 1rem;
				line-height: 24px;
				font-weight: 500;
			}
		}

		.hero-actions {
			width: 100%;
			button {
				width: 100%;
			}
		}
	}
}
