// SCSS file with shared styles

//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "custom";
@import "index";
@import "contact";
@import "about";

@import "@splidejs/splide/css";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	width: 100vw;
	background-color: rgb(1, 7, 18);
	overflow-x: hidden;
	font-family: "Yellix";
}

// Navbar

header {
	position: fixed;
	width: 100%;
	z-index: 10;
	padding: 0 2%;
	height: 100px;
	transition: 0.6s ease-out;
	border-bottom: 1px solid transparent;

	&.scrolled {
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		background: rgba(18, 18, 18, 0.01);
		backdrop-filter: blur(40px);
		transition: 0.6s ease-in;
	}

	#logo {
		height: 32px;
		width: auto;
	}

	.header-link-pill-container {
		.header-link-pill {
			padding-left: 1.125rem;
			padding-right: 0.75rem;
			border-radius: 1000px;
			background-color: rgba(252, 252, 252, 0.05);
			border: 1px solid rgba(225, 225, 226, 0.1);
			cursor: pointer;
			transition: 0.3s ease;

			&:hover {
				background-color: $color-neutral-500;
			}

			&:active,
			&:focus {
				box-shadow: 0 0 0 0.25rem $color-neutral-500;
			}
		}
	}

	.check-mtos {
		padding: 0.75rem 1.25rem;
		border-radius: 1000px;
		background-color: rgba(32, 41, 57, 1);
		cursor: pointer;
		transition: 0.3s ease;

		&:hover {
			background-color: $color-neutral-500;
		}

		&:active,
		&:focus {
			box-shadow: 0 0 0 0.25rem $color-neutral-500;
		}
	}

	.estimate-project {
		border-radius: 64px;
		border: 1px solid $color-primary-600;
		background: $color-primary-600;
		padding: 12px 20px;
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;

		@media (max-width: 600px) {
			display: none;
		}
	}

	.estimate-project:hover {
		background-color: $color-primary-700;
	}

	.nav-menu {
		background: none;
		border: none;
		cursor: pointer;
		padding: 8px;
		border-radius: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.nav-menu:hover {
		background-color: rgba(255, 255, 255, 0.2);
	}
}

// Navbar overlay

.overlay {
	height: 0;
	width: 100%;
	position: fixed;
	z-index: 11;
	top: 0;
	left: 0;
	background: #121212;
	overflow: hidden;
	transition: 0.5s;
}

#logo {
	height: 32px;
	width: auto;
}

.overlay-content {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: auto;

	.nav-content {
		margin-top: 6rem;
		p {
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.5rem;
			color: #464b53;
		}

		.nav-link-pill-container {
			max-height: fit-content;
			justify-content: center;

			.useful-links-container {
				justify-content: center;
				.nav-link-pill {
					font-size: 1rem;
					color: rgba(255, 255, 255, 1);
					line-height: 1.5rem;
					padding-left: 1.125rem;
					padding-right: 0.75rem;
					border-radius: 1000px;
					background-color: rgba(252, 252, 252, 0.05);
					border: 1px solid rgba(225, 225, 226, 0.1);
					cursor: pointer;
					transition: 0.3s ease;

					&:hover {
						background-color: $color-neutral-500;
					}

					&:active,
					&:focus {
						box-shadow: 0 0 0 4px $color-neutral-500;
					}
				}
			}
		}
	}

	.nav-links-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.overlay a {
	font-size: 96px;
	font-style: normal;
	font-weight: 600;
	line-height: 102px;
	letter-spacing: -1.92px;
	text-decoration: none;
	color: #464b53;
}

.overlay a:hover,
.overlay a:focus {
	color: #e8e8ea !important;
}

.closebtn {
	position: absolute;
	right: 15%;
	top: 4%;
	z-index: 12;
	background: none;
	border: none;
	cursor: pointer;
	padding: 8px;
	border-radius: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.closebtn:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.overlay-logo {
	position: absolute;
	left: 15%;
	top: 4%;
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: center;
}

// Section containers

.custom-container {
	display: flex;
	flex-direction: column;
	gap: 192px;
	backdrop-filter: blur(240px);
}

.section-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 64px;
	padding: 0 80px;
}

.section-content-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 44px;
}

.section-title {
	text-align: center;
	font-size: 4.5rem;
	font-weight: 500;
	font-style: normal;
	line-height: 90px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.section-subtitle {
	text-align: center;
}

.section-divider-container {
	width: 100vw;
	display: flex;
	justify-content: center;

	.section-divider {
		width: 100%;
		height: 1px;
		background-color: $color-neutral-800;
	}
}

// Footer section

#footer {
	padding: 64px 32px 20px 32px;

	.footer-middle-section {
		border-bottom: 1px solid $color-neutral-800;
		border-top: 1px solid $color-neutral-800;
		padding: 44px 0;
	}

	.eublockchain-logo {
		height: 20px;
		width: auto;
	}
}

#footer a {
	text-decoration: none;
}

#footer a:hover {
	color: $color-neutral-25;
}

.footer-section-divider-container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 96px;

	.footer-section-divider {
		width: 70%;
		height: 1px;
		background-color: $color-neutral-800;
	}
}

@media only screen and (max-width: 450px) {
	header {
		.check-mtos {
			padding: 0.75rem 0.625rem;
			font-size: 0.875rem;
		}
	}
}

@media only screen and (max-width: 600px) {
	// Navbar overlay

	.overlay .closebtn {
		right: 5%;
	}

	.overlay-logo {
		left: 5%;
	}

	.overlay a {
		font-size: 3.75rem;
		font-weight: 500;
		font-style: normal;
		line-height: 72px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}

	.overlay-content {
		align-items: end;
		padding: 0 20px;
		overflow: auto;
		.nav-content {
			margin-top: 6rem;
			.nav-link-pill-container {
				justify-content: flex-start;

				.useful-links-container {
					justify-content: start;
				}
			}
			.nav-links-container {
				align-items: flex-start;
			}
		}
	}

	// Section containers

	.section-container {
		padding: 0 16px;
	}

	.section-content-container {
		justify-content: flex-start;
		align-items: flex-start;
	}

	.section-title {
		text-align: left;
		font-size: 3rem;
		font-weight: 500;
		font-style: normal;
		line-height: 60px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}

	.section-subtitle {
		text-align: left;
	}

	// Footer section

	#footer {
		padding-bottom: 96px;

		.footer-middle-section {
			display: flex;
			flex-direction: column;
			gap: 16px;
			border-top: none;
		}
	}

	.footer-section-divider-container {
		.footer-section-divider {
			width: 100%;
		}
	}
}

@media only screen and (max-height: 890px) {
	// Navbar overlay

	.overlay .closebtn {
		right: 5%;
	}

	.overlay-logo {
		left: 5%;
	}

	.overlay a {
		font-size: 3.75rem;
		font-weight: 500;
		font-style: normal;
		line-height: 72px;
		letter-spacing: -0.02em;
		text-decoration: none;
		text-transform: none;
	}

	.overlay-content {
		padding: 0 20px;
	}
}

@media only screen and (max-width: 930px) {
	// Navbar
	header {
		padding: 0 5%;
		height: 82px;

		.estimate-project {
			display: none;
		}
	}
}

@media only screen and (max-width: 1480px) {
	// Navigation links for google drive documents in header

	.header-link-pill-container {
		display: none !important;
	}
}

//---------------------------------------------
// 3.Components
//---------------------------------------------
