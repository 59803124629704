/* Color variables */

$color-base-white: rgba(255, 255, 255, 1);
$color-base-black: rgba(18, 18, 18, 1);
$color-neutral-25: rgba(232, 232, 234, 1);
$color-neutral-50: rgba(209, 210, 212, 1);
$color-neutral-100: rgba(185, 187, 191, 1);
$color-neutral-200: rgba(162, 165, 169, 1);
$color-neutral-300: rgba(139, 142, 148, 1);
$color-neutral-400: rgba(116, 120, 126, 1);
$color-neutral-500: rgba(93, 97, 105, 1);
$color-neutral-600: rgba(70, 75, 83, 1);
$color-neutral-700: rgba(46, 52, 62, 1);
$color-neutral-800: rgba(23, 30, 40, 1);
$color-neutral-900: rgba(0, 7, 19, 1);
$color-primary-25: rgba(247, 253, 253, 1);
$color-primary-50: rgba(231, 249, 249, 1);
$color-primary-100: rgba(208, 244, 244, 1);
$color-primary-200: rgba(185, 238, 238, 1);
$color-primary-300: rgba(161, 233, 233, 1);
$color-primary-400: rgba(138, 227, 227, 1);
$color-primary-500: rgba(115, 222, 222, 1);
$color-primary-600: rgba(95, 185, 185, 1);
$color-primary-700: rgba(76, 148, 148, 1);
$color-primary-800: rgba(57, 111, 111, 1);
$color-primary-900: rgba(38, 74, 74, 1);
$color-primary-transparent-50: rgba(115, 222, 222, 0.1);
$color-primary-transparent-100: rgba(115, 222, 222, 0.2);
$color-primary-transparent-200: rgba(115, 222, 222, 0.3);
$color-primary-transparent-300: rgba(115, 222, 222, 0.4);
$color-primary-transparent-400: rgba(115, 222, 222, 0.5);
$color-primary-transparent-500: rgba(115, 222, 222, 0.6);
$color-primary-transparent-600: rgba(115, 222, 222, 0.7);
$color-primary-transparent-700: rgba(115, 222, 222, 0.8);
$color-primary-transparent-800: rgba(115, 222, 222, 0.9);
$color-primary-transparent-900: rgba(115, 222, 222, 1);
$color-secondary-25: rgba(248, 243, 255, 1);
$color-secondary-50: rgba(234, 221, 255, 1);
$color-secondary-100: rgba(213, 189, 255, 1);
$color-secondary-200: rgba(192, 156, 255, 1);
$color-secondary-300: rgba(171, 123, 255, 1);
$color-secondary-400: rgba(150, 90, 255, 1);
$color-secondary-500: rgba(130, 57, 255, 1);
$color-secondary-600: rgba(108, 47, 212, 1);
$color-secondary-700: rgba(86, 38, 170, 1);
$color-secondary-800: rgba(65, 28, 127, 1);
$color-secondary-900: rgba(43, 19, 85, 1);
$color-secondary-transparent-50: rgba(130, 57, 255, 0.1);
$color-secondary-transparent-100: rgba(130, 57, 255, 0.2);
$color-secondary-transparent-200: rgba(130, 57, 255, 0.3);
$color-secondary-transparent-300: rgba(130, 57, 255, 0.4);
$color-secondary-transparent-400: rgba(130, 57, 255, 0.5);
$color-secondary-transparent-500: rgba(130, 57, 255, 0.6);
$color-secondary-transparent-600: rgba(130, 57, 255, 0.7);
$color-secondary-transparent-700: rgba(130, 57, 255, 0.8);
$color-secondary-transparent-800: rgba(130, 57, 255, 0.9);
$color-tertiary-25: rgba(243, 250, 255, 1);
$color-tertiary-50: rgba(220, 240, 255, 1);
$color-tertiary-100: rgba(187, 226, 255, 1);
$color-tertiary-200: rgba(153, 212, 255, 1);
$color-tertiary-300: rgba(119, 197, 255, 1);
$color-tertiary-400: rgba(85, 183, 255, 1);
$color-tertiary-500: rgba(51, 169, 255, 1);
$color-tertiary-600: rgba(42, 140, 212, 1);
$color-tertiary-700: rgba(34, 112, 170, 1);
$color-tertiary-800: rgba(25, 84, 127, 1);
$color-tertiary-900: rgba(17, 56, 85, 1);
$color-tertiary-transparent-50: rgba(51, 169, 255, 0.1);
$color-tertiary-transparent-100: rgba(51, 169, 255, 0.2);
$color-tertiary-transparent-200: rgba(51, 169, 255, 0.3);
$color-tertiary-transparent-300: rgba(51, 169, 255, 0.4);
$color-tertiary-transparent-400: rgba(51, 169, 255, 0.5);
$color-tertiary-transparent-500: rgba(51, 169, 255, 0.6);
$color-tertiary-transparent-600: rgba(51, 169, 255, 0.7);
$color-tertiary-transparent-700: rgba(51, 169, 255, 0.8);
$color-tertiary-transparent-800: rgba(51, 169, 255, 0.9);
$color-info-error-25: rgba(254, 247, 246, 1);
$color-info-error-50: rgba(253, 232, 228, 1);
$color-info-error-100: rgba(251, 210, 202, 1);
$color-info-error-200: rgba(249, 188, 176, 1);
$color-info-error-300: rgba(247, 165, 149, 1);
$color-info-error-400: rgba(245, 143, 123, 1);
$color-info-error-500: rgba(244, 121, 97, 1);
$color-info-error-600: rgba(203, 100, 80, 1);
$color-info-error-700: rgba(162, 80, 64, 1);
$color-info-error-800: rgba(122, 60, 48, 1);
$color-info-error-900: rgba(81, 40, 32, 1);
$color-info-warning-25: rgba(254, 251, 246, 1);
$color-info-warning-50: rgba(254, 245, 228, 1);
$color-info-warning-100: rgba(253, 236, 202, 1);
$color-info-warning-200: rgba(252, 226, 176, 1);
$color-info-warning-300: rgba(251, 217, 149, 1);
$color-info-warning-400: rgba(250, 207, 123, 1);
$color-info-warning-500: rgba(250, 198, 97, 1);
$color-info-warning-600: rgba(208, 165, 80, 1);
$color-info-warning-700: rgba(166, 132, 64, 1);
$color-info-warning-800: rgba(125, 99, 48, 1);
$color-info-warning-900: rgba(83, 66, 32, 1);
$color-info-success-25: rgba(250, 254, 245, 1);
$color-info-success-50: rgba(243, 254, 231, 1);
$color-info-success-100: rgba(227, 251, 204, 1);
$color-info-success-200: rgba(208, 248, 171, 1);
$color-info-success-300: rgba(166, 239, 103, 1);
$color-info-success-400: rgba(133, 225, 58, 1);
$color-info-success-500: rgba(102, 198, 28, 1);
$color-info-success-600: rgba(76, 163, 13, 1);
$color-info-success-700: rgba(59, 124, 15, 1);
$color-info-success-800: rgba(50, 98, 18, 1);
$color-info-success-900: rgba(43, 83, 20, 1);

/* Text color styles */

.colorBaseWhite {
	color: $color-base-white;
}
.colorBaseBlack {
	color: $color-base-black;
}
.colorNeutral25 {
	color: $color-neutral-25;
}
.colorNeutral50 {
	color: $color-neutral-50;
}
.colorNeutral100 {
	color: $color-neutral-100;
}
.colorNeutral200 {
	color: $color-neutral-200;
}
.colorNeutral300 {
	color: $color-neutral-300;
}
.colorNeutral400 {
	color: $color-neutral-400;
}
.colorNeutral500 {
	color: $color-neutral-500;
}
.colorNeutral600 {
	color: $color-neutral-600;
}
.colorNeutral700 {
	color: $color-neutral-700;
}
.colorNeutral800 {
	color: $color-neutral-800;
}
.colorNeutral900 {
	color: $color-neutral-900;
}
.colorPrimary25 {
	color: $color-primary-25;
}
.colorPrimary50 {
	color: $color-primary-50;
}
.colorPrimary100 {
	color: $color-primary-100;
}
.colorPrimary200 {
	color: $color-primary-200;
}
.colorPrimary300 {
	color: $color-primary-300;
}
.colorPrimary400 {
	color: $color-primary-400;
}
.colorPrimary500 {
	color: $color-primary-500;
}
.colorPrimary600 {
	color: $color-primary-600;
}
.colorPrimary700 {
	color: $color-primary-700;
}
.colorPrimary800 {
	color: $color-primary-800;
}
.colorPrimary900 {
	color: $color-primary-900;
}
.colorPrimaryTransparent50 {
	color: $color-primary-transparent-50;
}
.colorPrimaryTransparent100 {
	color: $color-primary-transparent-100;
}
.colorPrimaryTransparent200 {
	color: $color-primary-transparent-200;
}
.colorPrimaryTransparent300 {
	color: $color-primary-transparent-300;
}
.colorPrimaryTransparent400 {
	color: $color-primary-transparent-400;
}
.colorPrimaryTransparent500 {
	color: $color-primary-transparent-500;
}
.colorPrimaryTransparent600 {
	color: $color-primary-transparent-600;
}
.colorPrimaryTransparent700 {
	color: $color-primary-transparent-700;
}
.colorPrimaryTransparent800 {
	color: $color-primary-transparent-800;
}
.colorPrimaryTransparent900 {
	color: $color-primary-transparent-900;
}
.colorSecondary25 {
	color: $color-secondary-25;
}
.colorSecondary50 {
	color: $color-secondary-50;
}
.colorSecondary100 {
	color: $color-secondary-100;
}
.colorSecondary200 {
	color: $color-secondary-200;
}
.colorSecondary300 {
	color: $color-secondary-300;
}
.colorSecondary400 {
	color: $color-secondary-400;
}
.colorSecondary500 {
	color: $color-secondary-500;
}
.colorSecondary600 {
	color: $color-secondary-600;
}
.colorSecondary700 {
	color: $color-secondary-700;
}
.colorSecondary800 {
	color: $color-secondary-800;
}
.colorSecondary900 {
	color: $color-secondary-900;
}
.colorSecondaryTransparent50 {
	color: $color-secondary-transparent-50;
}
.colorSecondaryTransparent100 {
	color: $color-secondary-transparent-100;
}
.colorSecondaryTransparent200 {
	color: $color-secondary-transparent-200;
}
.colorSecondaryTransparent300 {
	color: $color-secondary-transparent-300;
}
.colorSecondaryTransparent400 {
	color: $color-secondary-transparent-400;
}
.colorSecondaryTransparent500 {
	color: $color-secondary-transparent-500;
}
.colorSecondaryTransparent600 {
	color: $color-secondary-transparent-600;
}
.colorSecondaryTransparent700 {
	color: $color-secondary-transparent-700;
}
.colorSecondaryTransparent800 {
	color: $color-secondary-transparent-800;
}
.colorTertiary25 {
	color: $color-tertiary-25;
}
.colorTertiary50 {
	color: $color-tertiary-50;
}
.colorTertiary100 {
	color: $color-tertiary-100;
}
.colorTertiary200 {
	color: $color-tertiary-200;
}
.colorTertiary300 {
	color: $color-tertiary-300;
}
.colorTertiary400 {
	color: $color-tertiary-400;
}
.colorTertiary500 {
	color: $color-tertiary-500;
}
.colorTertiary600 {
	color: $color-tertiary-600;
}
.colorTertiary700 {
	color: $color-tertiary-700;
}
.colorTertiary800 {
	color: $color-tertiary-800;
}
.colorTertiary900 {
	color: $color-tertiary-900;
}
.colorTertiaryTransparent50 {
	color: $color-tertiary-transparent-50;
}
.colorTertiaryTransparent100 {
	color: $color-tertiary-transparent-100;
}
.colorTertiaryTransparent200 {
	color: $color-tertiary-transparent-200;
}
.colorTertiaryTransparent300 {
	color: $color-tertiary-transparent-300;
}
.colorTertiaryTransparent400 {
	color: $color-tertiary-transparent-400;
}
.colorTertiaryTransparent500 {
	color: $color-tertiary-transparent-500;
}
.colorTertiaryTransparent600 {
	color: $color-tertiary-transparent-600;
}
.colorTertiaryTransparent700 {
	color: $color-tertiary-transparent-700;
}
.colorTertiaryTransparent800 {
	color: $color-tertiary-transparent-800;
}
.colorInfoError25 {
	color: $color-info-error-25;
}
.colorInfoError50 {
	color: $color-info-error-50;
}
.colorInfoError100 {
	color: $color-info-error-100;
}
.colorInfoError200 {
	color: $color-info-error-200;
}
.colorInfoError300 {
	color: $color-info-error-300;
}
.colorInfoError400 {
	color: $color-info-error-400;
}
.colorInfoError500 {
	color: $color-info-error-500;
}
.colorInfoError600 {
	color: $color-info-error-600;
}
.colorInfoError700 {
	color: $color-info-error-700;
}
.colorInfoError800 {
	color: $color-info-error-800;
}
.colorInfoError900 {
	color: $color-info-error-900;
}
.colorInfoWarning25 {
	color: $color-info-warning-25;
}
.colorInfoWarning50 {
	color: $color-info-warning-50;
}
.colorInfoWarning100 {
	color: $color-info-warning-100;
}
.colorInfoWarning200 {
	color: $color-info-warning-200;
}
.colorInfoWarning300 {
	color: $color-info-warning-300;
}
.colorInfoWarning400 {
	color: $color-info-warning-400;
}
.colorInfoWarning500 {
	color: $color-info-warning-500;
}
.colorInfoWarning600 {
	color: $color-info-warning-600;
}
.colorInfoWarning700 {
	color: $color-info-warning-700;
}
.colorInfoWarning800 {
	color: $color-info-warning-800;
}
.colorInfoWarning900 {
	color: $color-info-warning-900;
}
.colorInfoSuccess25 {
	color: $color-info-success-25;
}
.colorInfoSuccess50 {
	color: $color-info-success-50;
}
.colorInfoSuccess100 {
	color: $color-info-success-100;
}
.colorInfoSuccess200 {
	color: $color-info-success-200;
}
.colorInfoSuccess300 {
	color: $color-info-success-300;
}
.colorInfoSuccess400 {
	color: $color-info-success-400;
}
.colorInfoSuccess500 {
	color: $color-info-success-500;
}
.colorInfoSuccess600 {
	color: $color-info-success-600;
}
.colorInfoSuccess700 {
	color: $color-info-success-700;
}
.colorInfoSuccess800 {
	color: $color-info-success-800;
}
.colorInfoSuccess900 {
	color: $color-info-success-900;
}

/* Text styles */

.textStyleDisplay3XlRegular {
	font-size: 6rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 102px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplay3XlMedium {
	font-size: 6rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 102px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplay3XlSemibold {
	font-size: 6rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 102px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplay3XlBold {
	font-size: 6rem;
	font-family: "Yellix";
	font-weight: 800;
	font-style: normal;
	line-height: 102px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplay2XlRegular {
	font-size: 4.5rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 90px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}
.textStyleDisplay2XlMedium {
	font-size: 4.5rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 90px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplay2XlSemibold {
	font-size: 4.5rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 90px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplay2XlBold {
	font-size: 4.5rem;
	font-family: "Yellix";
	font-weight: 800;
	font-style: normal;
	line-height: 90px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXlNormal {
	font-size: 3.75rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 72px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXlMedium {
	font-size: 3.75rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 72px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXlSemibold {
	font-size: 3.75rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 72px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXlBold {
	font-size: 3.75rem;
	font-family: "Yellix";
	font-weight: 800;
	font-style: normal;
	line-height: 72px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayLgNormal {
	font-size: 3rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 60px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayLgMedium {
	font-size: 3rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 60px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayLgSemibold {
	font-size: 3rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 60px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayLgBold {
	font-size: 3rem;
	font-family: "Yellix";
	font-weight: 800;
	font-style: normal;
	line-height: 60px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayMdNormal {
	font-size: 2.25rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 44px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayMdMedium {
	font-size: 2.25rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 44px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayMdSemibold {
	font-size: 2.25rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 44px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayMdBold {
	font-size: 2.25rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 44px;
	letter-spacing: -0.02em;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplaySmNormal {
	font-size: 1.875rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 38px;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplaySmMedium {
	font-size: 1.875rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 38px;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplaySmSemibold {
	font-size: 1.875rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 38px;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplaySmBold {
	font-size: 1.875rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 38px;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXsNormal {
	font-size: 1.5rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 32px;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXsMedium {
	font-size: 1.5rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 32px;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXsSemibold {
	font-size: 1.5rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 32px;
	text-decoration: none;
	text-transform: none;
}

.textStyleDisplayXsBold {
	font-size: 1.5rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 32px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXlNormal {
	font-size: 1.25rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 30px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXlMedium {
	font-size: 1.25rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 30px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXlSemibold {
	font-size: 1.25rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 30px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXlBold {
	font-size: 1.25rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 30px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextLgNormal {
	font-size: 1.125rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 28px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextLgMedium {
	font-size: 1.125rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 28px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextLgBold {
	font-size: 1.125rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 28px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextMdNormal {
	font-size: 1rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 24px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextMdMedium {
	font-size: 1rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 24px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextMdSemibold {
	font-size: 1rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 24px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextMdBold {
	font-size: 1rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 24px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextSmNormal {
	font-size: 0.875rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextSmMedium {
	font-size: 0.875rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 20px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextSmSemibold {
	font-size: 0.875rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 20px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextSmBold {
	font-size: 0.875rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 20px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXsNormal {
	font-size: 0.75rem;
	font-family: "Yellix";
	font-weight: 400;
	font-style: normal;
	line-height: 18px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXsMedium {
	font-size: 0.75rem;
	font-family: "Yellix";
	font-weight: 500;
	font-style: normal;
	line-height: 18px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXsSemibold {
	font-size: 0.75rem;
	font-family: "Yellix";
	font-weight: 600;
	font-style: normal;
	line-height: 18px;
	text-decoration: none;
	text-transform: none;
}

.textStyleTextXsBold {
	font-size: 0.75rem;
	font-family: "Yellix";
	font-weight: 700;
	font-style: normal;
	line-height: 18px;
	text-decoration: none;
	text-transform: none;
}

/* Drop shadows */

.effectStyle4PxPrimary500Transparent {
	box-shadow: 0px 0px 0px 4px rgba(15, 62, 62, 1);
}

.effectStyleFocusRing4PxPrimary100 {
	box-shadow: 0px 0px 0px 4px rgba(208, 244, 244, 1);
}

.effectStyleFocusRing4PxGrey100 {
	box-shadow: 0px 0px 0px 4px rgba(238, 242, 246, 1);
}

.effectStyleFocusRing4PxError100 {
	box-shadow: 0px 0px 0px 4px rgba(251, 210, 202, 1);
}

.effectStyleFocusRing4PxGrey500 {
	box-shadow: 0px 0px 0px 4px rgba(105, 117, 134, 1);
}

.effectStyleFocusRing4PxPrimary500 {
	box-shadow: 0px 0px 0px 4px rgba(115, 222, 222, 1);
}

.effectStyleShadowXs {
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.effectStyleShadowSm {
	box-shadow:
		0px 1px 3px rgba(16, 24, 40, 0.1),
		0px 1px 2px rgba(16, 24, 40, 0.06);
}

.effectStyleShadowMd {
	box-shadow:
		0px 4px 8px -2px rgba(16, 24, 40, 0.1),
		0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.effectStyleShadowLg {
	box-shadow:
		0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.effectStyleShadowXl {
	box-shadow:
		0px 20px 24px -4px rgba(16, 24, 40, 0.08),
		0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.effectStyleShadow2Xl {
	box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
}

.effectStyleShadow3Xl {
	box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
}

.effectStyleBackgroundBlurSm {
	backdrop-filter: blur(8px);
}

.effectStyleBackgroundBlurMd {
	backdrop-filter: blur(16px);
}

.effectStyleBackgroundBlurLg {
	backdrop-filter: blur(24px);
}

.effectStyleBackgroundBlurXl {
	backdrop-filter: blur(40px);
}
